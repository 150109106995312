
html, body, #root {
  width: 100%;
  height: 100%;
}

.font-source {
  font-family: "Source Sans Pro", sans-serif;
}

.fg-gray-700 {
  color: #374151;
}

.-px-5p{
  margin-left: -5%;
  margin-right: -5%;
}

.px-5p{
  padding-left: 5%;
  padding-right: 5%;
}

.h-1px{
  height: 1px;
}
.border-bottom {
  border-bottom: 1px solid;
}

/* NAVBAR */
.dropdown:hover .dropdown-menu {
  display: block;
}

/* BREADCRUMB */
#breadcrumb {
  list-style: none;
  display: inline-block;
}

#breadcrumb li {
  float: left;
}
.dotted-underline{
  text-decoration:underline;
  text-decoration-style: dotted;
}
#breadcrumb li span {
  display: block;
  background: #f9fafb;
  text-decoration: none;
  --tw-text-opacity: 1;
  color: rgba(107, 114, 128, var(--tw-text-opacity));
  position: relative;
  height: 40px;
  line-height: 40px;
  padding: 0 5px 0 5px;
  text-align: center;
  margin-right: 25px;
}
#breadcrumb li:nth-child(even) span {
  background-color: #f9fafb;
}
#breadcrumb li:nth-child(even) span:before {
  border-color: #f9fafb;
  border-left-color: transparent;
}
#breadcrumb li:nth-child(even) span:after {
  border-left-color: #ffffff;
}
#breadcrumb li:first-child span {
  padding-left: 10px;
  -moz-border-radius: 10px 0 0 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px 0 0 4px;
}
#breadcrumb li:first-child span:before {
  border: none;
}
#breadcrumb li:last-child span {
  padding-right: 10px;
  -moz-border-radius: 0 4px 4px 0;
  -webkit-border-radius: 0;
  border-radius: 0 4px 4px 0;
  margin-right: 0px;
}
#breadcrumb li:last-child span:after {
  border: none;
}
#breadcrumb li span:before, #breadcrumb li span:after {
  content: "";
  position: absolute;
  top: 0;
  border: 0 solid #f9fafb;
  border-width: 20px 10px;
  width: 0;
  height: 0;
}
#breadcrumb li span:before {
  left: -20px;
  border-left-color: transparent;
}
#breadcrumb li span:after {
  left: 100%;
  border-color: transparent;
  border-left-color: #f9fafb;
}
#breadcrumb li span.active {
  background-color: #f3f4f6;
}

#breadcrumb li span.active {
  color: #000000;
  background-color: #f3f4f6;
}
#breadcrumb li span.active:before {
  border-color: #f3f4f6;
  border-left-color: transparent;
}
#breadcrumb li span.active:after {
  border-left-color: #f3f4f6;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type='checkbox'] {
  /* remove browser chrome */
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  outline: none;
  /*add styling */
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  border: 1px solid #505f66;
  overflow: hidden;
  border-radius: 3px;
  cursor: pointer;
}
input[type='checkbox']::before {
  content: '';
  color: #fff;
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  left: 4px;
  background-color: transparent;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 2px;
  -webkit-transform: scale(0);
          transform: scale(0);
  /* -webkit-transition: -webkit-transform 0.25s ease-in-out;
  transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out; */
  /* base64 encoded to make things easier to show 
  	normally you would use an image or a font
  */
  background-image: url("data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjQ0OCIgaGVpZ2h0PSI0NDgiIHZpZXdCb3g9IjAgMCA0NDggNDQ4Ij4KPHRpdGxlPjwvdGl0bGU+CjxnIGlkPSJpY29tb29uLWlnbm9yZSI+CjwvZz4KPHBhdGggZD0iTTQxNy43NSAxNDEuNWMwIDYuMjUtMi41IDEyLjUtNyAxN2wtMjE1IDIxNWMtNC41IDQuNS0xMC43NSA3LTE3IDdzLTEyLjUtMi41LTE3LTdsLTEyNC41LTEyNC41Yy00LjUtNC41LTctMTAuNzUtNy0xN3MyLjUtMTIuNSA3LTE3bDM0LTM0YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsNzMuNSA3My43NSAxNjQtMTY0LjI1YzQuNS00LjUgMTAuNzUtNyAxNy03czEyLjUgMi41IDE3IDdsMzQgMzRjNC41IDQuNSA3IDEwLjc1IDcgMTd6Ij48L3BhdGg+Cjwvc3ZnPgo=");
}

input[type='checkbox']:checked::before {
  -webkit-transform: scale(1);
          transform: scale(1);
}
.text-xxs {
  font-size: 0.625rem;
  line-height: 0.75rem;
}

img:not([alt]) {
  border: 5px solid red;
}

.required-wrapper {
  position: relative;
  display: inline-block;
  z-index: 1;
}
.required-wrapper input {
  padding-right: 14px;
}
.required-wrapper:after {
  content: "*";
  font-size: 40px;
  position: absolute;
  right: 10px;
  bottom: 1px;
  color: #d1d5db;
  z-index: 5;
}

.required-wrapper2 {
  position: relative;
  z-index: 1;
}
.required-wrapper2 input {
  padding-right: 14px;
}
.required-wrapper2:after {
  content: "*";
  font-size: 40px;
  position: absolute;
  right: 10px;
  bottom: -20px;
  color: #d1d5db;
  z-index: 5;
}
.carousel:hover .slide .legend {
  opacity: 1 !important;
}
/* carousel override */
.carousel .slide .legend {
    opacity: 0 !important;
    color: #fff9de !important;
    bottom: 80px !important;
    text-shadow: 0 1px 2px rgb(0 0 0 / 60%);
    border-radius: initial !important;
    left: initial !important;
    margin-left: initial !important;
    background: #361919 !important;
    background: rgba(54, 25, 25, .5) !important;
    font: 2rem 'Crimson Text', normal !important;
    width: 100% !important;
}
.right-5p { 
  right: 5%;
}

/* mgmindex */
.coinCaption {
  font-weight: bold;
  padding-top: 1em;
  padding-bottom: 1em;
}

div.center, div.floatnone {
  display: flex;
  justify-content: center;
}

/* mgmindex end */ 


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  text-transform: none;
}
::-moz-placeholder { /* Firefox 19+ */
  text-transform: none;
}
:-ms-input-placeholder { /* IE 10+ */
  text-transform: none;
}
:-moz-placeholder { /* Firefox 18- */
  text-transform: none;
}

@font-face {
  font-family: 'Crimson Text';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/crimson-text-v11-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/crimson-text-v11-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/crimson-text-v11-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/crimson-text-v11-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/crimson-text-v11-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/crimson-text-v11-latin-regular.svg#CrimsonText') format('svg'); /* Legacy iOS */
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/source-sans-pro-v14-latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('./fonts/source-sans-pro-v14-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/source-sans-pro-v14-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('./fonts/source-sans-pro-v14-latin-regular.woff') format('woff'), /* Modern Browsers */
       url('./fonts/source-sans-pro-v14-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('./fonts/source-sans-pro-v14-latin-regular.svg#SourceSansPro') format('svg'); /* Legacy iOS */
}

@tailwind base;
@tailwind components;
@tailwind utilities;

.rc-slider-mark .rc-slider-mark-text:first-of-type { 
  transform: translateX(0) !important;
}

.rc-slider-mark .rc-slider-mark-text:nth-of-type(2) { 
  transform: translateX(-100%) !important;
}
@media (min-width: 768px) {

  .muenzenPattern:before { 
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    background-image: url("./backgrounds/muenzenPattern.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    -webkit-transition: opacity 0.35s ease-in-out;
    -moz-transition: opacity 0.35s ease-in-out;
    -ms-transition: opacity 0.35s ease-in-out;
    -o-transition: opacity 0.35s ease-in-out;
    transition: opacity 0.35s ease-in-out;
  }

  .muenzenPattern:hover::before, .medalPattern:hover::before, .numlitPattern:hover::before , .banknotenPattern:hover::before{ 
    opacity: 0;
  }

  .medalPattern:before { 
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    background-image: url("./backgrounds/medalPattern.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    -webkit-transition: opacity 0.35s ease-in-out;
    -moz-transition: opacity 0.35s ease-in-out;
    -ms-transition: opacity 0.35s ease-in-out;
    -o-transition: opacity 0.35s ease-in-out;
    transition: opacity 0.35s ease-in-out;
  }

  .numlitPattern:before { 
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    background-image: url("./backgrounds/numlitPattern.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    -webkit-transition: opacity 0.35s ease-in-out;
    -moz-transition: opacity 0.35s ease-in-out;
    -ms-transition: opacity 0.35s ease-in-out;
    -o-transition: opacity 0.35s ease-in-out;
    transition: opacity 0.35s ease-in-out;
  }

  .banknotenPattern:before { 
    content: ' ';
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 1;
    background-image: url("./backgrounds/banknotenPattern.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    -ms-background-size: cover;
    -o-background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    background-size: cover;
    -webkit-transition: opacity 0.35s ease-in-out;
    -moz-transition: opacity 0.35s ease-in-out;
    -ms-transition: opacity 0.35s ease-in-out;
    -o-transition: opacity 0.35s ease-in-out;
    transition: opacity 0.35s ease-in-out;
  }
}
@media (min-width: 768px) {
  .h-tile {
    max-height: 40rem;
  }
}

figure {
  background-repeat: no-repeat;
}

figure:hover img {
  opacity: 0;
}


/* Accordion styles */
.tab-owner:hover .plsbtn {
  background:rgb(189, 189, 189);
}

.plsbtn::after {
  content: "+"
}

input:checked + .tab-owner > .plsbtn::after {
  content: "-";
}
input:checked ~ .tab-content {
  max-height: 10000px;
  padding: 1em;
}

.scrolling-wrapper {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

}

.scroll-card {
  flex: 0 0 auto;
}

.scrolling-wrapper, .scrolling-wrapper-flexbox {

  -webkit-overflow-scrolling: touch;
}
.scrolling-wrapper::-webkit-scrollbar {
  width: 4px;
  height: 8px;
  /*background-color: rgba(229, 231, 235, var(--bg-opacity));*/

}
.scrolling-wrapper::-webkit-scrollbar-track {
  background-color: rgba(229, 231, 235, var(--bg-opacity));
  /*background: red;*/
}
.scrolling-wrapper::-webkit-scrollbar-thumb {
  background-color: #000000;
  /*outline: 1px solid slategrey;*/
}


.min-w-56 { 
  min-width: 14rem;
}

.selectypadding {
  padding-top: 0.7rem;
  padding-bottom: 0.725rem;
}